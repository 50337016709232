import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMapTo,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { ProfileSelectors } from '@app/core';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { isValidPrescriber } from '@app/features/renewals/shared/renewal-utils';
import { MedicationRegimen } from '@app/modules/medications/shared';
import { mapDecimalValidationErrors } from '@app/modules/rx-cart/shared/rx-cart-utils';
import { PrescriberCredentialsActions } from '@app/modules/rx-cart/store/prescriber-credentials.actions';
import { PrescriberCredentialsSelectors } from '@app/modules/rx-cart/store/prescriber-credentials.selectors';
import { Prescriber } from '@app/modules/shared-rx/prescriber-credential.type';
import {
  numberOfValidPrescribers,
  sortValidPrescribers,
} from '@app/modules/shared-rx/utils';
import { filterTruthy } from '@app/utils';

import {
  ChangeRxForm,
  customRxChangeRequestOptionId,
} from '../../shared/change-rx-form';
import {
  ChangeRx,
  ChangeRxTypes,
  RxChangeRequestOption,
} from '../../shared/change-rx.type';
import { ChangeRxSelectors } from '../../store/change-rx.selectors';

@Component({
  selector: 'omg-change-rx-medication-change',
  templateUrl: './change-rx-medication-change.component.html',
  styleUrls: ['./change-rx-medication-change.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeRxMedicationChangeComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() form: ChangeRxForm;
  @Input() changeRx: ChangeRx;

  regimenEditable = true;
  changeRxTypes = ChangeRxTypes;
  rxChangeRequestOptions: RxChangeRequestOption[];

  isProvider$: Observable<boolean>;
  isCredentialsLoading$: Observable<boolean>;
  userCanPrescribe$: Observable<boolean>;

  numberOfValidPrescribers: number;
  validPrescribers: Prescriber[];

  credentialItems$: Observable<any>;
  updateErrors$: Observable<string[]>;

  notesToPharmacistCtrl$: Observable<AbstractControl>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private profileSelectors: ProfileSelectors,
    private prescriberCredentialsSelectors: PrescriberCredentialsSelectors,
    private prescriberCredentialsActions: PrescriberCredentialsActions,
    private changeRxSelectors: ChangeRxSelectors,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit(): void {
    this.setupSigningCredentials();
    this.setupListeners();
  }

  ngOnChanges(): void {
    this.buildRxChangeRequestOptions();
  }

  /* istanbul ignore next */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  regimenUpdate(medicationRegimen: MedicationRegimen) {
    this.form.controls.patchValue({ medicationRegimen });
  }

  updatedSelectedRxChangeRequestOption(option: RxChangeRequestOption) {
    this.form.controls.patchValue({
      selectedRxChangeRequestOptionId: option.id,
      quantity: option.quantity,
      refills: option.fills,
      dispenseAsWritten: option.dispenseAsWritten,
      earliestFillDate: option.earliestFillDate,
      medicationRegimen: option.medicationRegimen,
      medicationPackageSizeId:
        option.packageOptions.defaultOption?.matchedOption?.id,
    });
  }

  private setupSigningCredentials() {
    this.numberOfValidPrescribers = numberOfValidPrescribers(
      this.changeRx.rxChangeRequest,
    );

    this.validPrescribers = sortValidPrescribers(
      this.changeRx.rxChangeRequest.validPrescribers || [],
    );

    this.userCanPrescribe$ = combineLatest([
      this.profileSelectors.hasRole('provider'),
      this.profileSelectors.profileId,
    ]).pipe(
      tap(([_, profileId]) => {
        const control = this.form.controls.get('prescriberId');
        if (
          !control.value &&
          isValidPrescriber(profileId, this.validPrescribers)
        ) {
          control.patchValue(profileId);
        }
      }),
      map(
        ([isProvider, profileId]) =>
          isProvider || isValidPrescriber(profileId, this.validPrescribers),
      ),
    );

    this.isCredentialsLoading$ = this.prescriberCredentialsSelectors.pending;
    this.credentialItems$ = this.prescriberCredentialsSelectors.credentialDropdownItems;
  }

  private setupListeners() {
    this.form.controls
      .get('prescriberId')
      .valueChanges.pipe(
        startWith(this.form.controls.get('prescriberId').value),
        filter(prescriberId =>
          isValidPrescriber(prescriberId, this.validPrescribers),
        ),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(prescriberId =>
        this.prescriberCredentialsActions.getPrescriberCredentials({
          cartId: this.changeRx.rxChangeRequest.rxCart.id,
          prescriberId,
        }),
      );

    this.prescriberCredentialsSelectors.pending
      .pipe(
        filter(pending => !pending),
        switchMapTo(this.prescriberCredentialsSelectors.prescriberCredentials),
        map(prescriberCredentials => prescriberCredentials.id),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(prescribingCredentialId =>
        this.form.controls.patchValue({ prescribingCredentialId }),
      );

    this.updateErrors$ = this.changeRxSelectors.loading.pipe(
      withLatestFrom(this.changeRxSelectors.error),
      map(([_, state]) =>
        mapDecimalValidationErrors([
          { label: 'Quantity', control: this.form.controls.get('quantity') },
          { label: 'Total Fills', control: this.form.controls.get('refills') },
        ]).concat(Object.values(state?.error?.errors || {})),
      ),
    );

    this.notesToPharmacistCtrl$ = this.launchDarklyService
      .variation$(FeatureFlagNames.medsChangeRxNotesToPharmacist, false)
      .pipe(
        filterTruthy(),
        map(() => this.form.controls.get('notesToPharmacist')),
      );
  }

  private isSelectedOption(option: RxChangeRequestOption) {
    const {
      quantity,
      refills,
      dispenseAsWritten,
      earliestFillDate,
      medicationRegimen,
      medicationPackageSizeId,
    } = this.form.controls.value;

    return (
      option.quantity === quantity &&
      option.fills === refills &&
      option.dispenseAsWritten === dispenseAsWritten &&
      option.earliestFillDate?.toLocaleDateString() ===
        earliestFillDate?.toLocaleDateString() &&
      option.medicationRegimen.id === medicationRegimen.id &&
      option.packageOptions.defaultOption?.matchedOption?.id ===
        medicationPackageSizeId
    );
  }

  private buildRxChangeRequestOptions() {
    const { rxChangeRequestOptions } = this.changeRx.rxChangeRequest;
    this.rxChangeRequestOptions = [].concat(rxChangeRequestOptions);

    let matchedOption = rxChangeRequestOptions.find(opt =>
      this.isSelectedOption(opt),
    );
    if (!matchedOption) {
      const customOption = this.buildCustomRxChangeRequestOption();
      this.rxChangeRequestOptions.push(customOption);
      matchedOption = customOption;
    }

    if (
      matchedOption.id !== this.form.value.selectedRxChangeRequestOptionId ||
      !this.isSelectedOption(matchedOption)
    ) {
      this.updatedSelectedRxChangeRequestOption(matchedOption);
    }
  }

  private buildCustomRxChangeRequestOption(): RxChangeRequestOption {
    return {
      ...this.changeRx.rxChangeRequest,
      id: customRxChangeRequestOptionId,
      custom: true,
      fills: this.changeRx.rxChangeRequest.approvedFills,
      quantity: this.changeRx.rxChangeRequest.approvedQuantity,
    };
  }
}
