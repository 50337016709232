import { Component } from '@angular/core';

import { FullPrescriptionHistoryComponent } from '@app/features/prescription-history/components/full-prescription-history/full-prescription-history.component';
import { DialogService } from '@app/shared/components/dialog';

@Component({
  selector: 'omg-pdmp-rx-dialogue',
  templateUrl: './pdmp-rx-dialogue.component.html',
  styleUrls: ['./pdmp-rx-dialogue.component.scss'],
})
export class PdmpRxDialogueComponent {
  constructor(private dialogService: DialogService) {}

  onShowFullRxHistoryClick() {
    const dialogRef = this.dialogService.open(
      FullPrescriptionHistoryComponent,
      {
        autoFocus: true,
        disableClose: false,
      },
    );

    if (dialogRef) {
      dialogRef.componentInstance.currentHistoryTab = 'PDMP';
    }
  }
}
