import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { AnalyticsActions, FeatureFlagSelectors, Patient } from '@app/core';
import { AnalyticsEvent } from '@app/core/analytics/shared/analytics.type';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { PatientSelectors } from '@app/core/patient/store/patient.selectors';
import { ProcedureSuggestion } from '@app/features/procedure-suggestion/shared/procedure-suggestion.type';
import { ProcedureSuggestionSelectors } from '@app/features/procedure-suggestion/store/procedure-suggestion.selectors';
import { ChronicCareManagementComponent } from '@app/features/program-visit/components/chronic-care-management/chronic-care-management.component';
import { VisitProcedure } from '@app/features/service-ticket/shared/visit-procedure.type';
import { VisitProcedureActions } from '@app/features/service-ticket/store/visit-procedure.actions';
import { VisitProcedureSelectors } from '@app/features/service-ticket/store/visit-procedure.selectors';
import { S3Pointer } from '@app/modules/aws/shared/aws-session.type';
import { createAttachmentKey } from '@app/modules/aws/shared/s3-utils';
import { S3Service } from '@app/modules/aws/shared/s3.service';
import { Commentable } from '@app/modules/comments/shared/comments.type';
import {
  HelpRequestConfirmationModalComponent,
  HelpRequestOptions,
} from '@app/modules/help-request';
import { ReferenceDataKeys } from '@app/modules/reference-data';
import { ReferenceDataSelectors } from '@app/modules/reference-data/store/reference-data.selectors';
import { Todo } from '@app/modules/todo/shared/todo.type';
import {
  MeasurementValidationRules,
  VitalsValidationRanges,
} from '@app/modules/vitals-data/shared/measurement-validation-rules.type';
import { VitalSetFormService } from '@app/modules/vitals-data/shared/vital-set-form.service';
import { vitalTypes } from '@app/modules/vitals-data/shared/vitals-data.type';
import { DialogService, FormModel } from '@app/shared';
import { CollapseDirective } from '@app/shared/components/collapse';
import { ToastMessageService } from '@app/shared/components/toast';
import { filterTruthy } from '@app/utils';
import { proxyWith } from '@app/utils/observables';

import { LaunchDarklyService } from '../../../../core/launch-darkly/launchdarkly.service';
import { basicPatientDetails } from '../../../../core/patient/shared/patient-utils';
import { PatientChartActions } from '../../../patient-chart/store/patient-chart.actions';
import { PatientChartSelectors } from '../../../patient-chart/store/patient-chart.selectors';
import { SummariesApiService } from '../../shared/summaries-api.service';
import {
  mapSummaryToSummaryProviderRecommendationUpdateData,
  mapSummaryToUnsignedForm,
  mapUnsignedFormToSummaryUpdate,
} from '../../shared/summaries-form.mappers';
import {
  isMentalHealthVisit,
  isOfficeVisit,
  isWalkinVisit,
} from '../../shared/summaries-utils';
import {
  Summary,
  SummaryAppointment,
  SummaryProblem,
} from '../../shared/summaries.type';
import { SummariesActions } from '../../store/summaries.actions';
import { SummariesSelectors } from '../../store/summaries.selectors';

@Component({
  selector: 'omg-unsigned-summary',
  templateUrl: './unsigned-summary.component.html',
  styleUrls: ['./unsigned-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsignedSummaryComponent implements OnInit, OnDestroy, OnChanges {
  @Input() summary: Summary;
  @Input() todo: Todo;
  @Input() commentsCollapseRef: CollapseDirective;

  @ViewChild('coSignCollapseRef', { static: false })
  private coSignCollapseRef: CollapseDirective;
  coSignAssignee: any; // Need to store it here to not trigger autosave when changed

  @ViewChild(ChronicCareManagementComponent)
  chronicCareComponent: ChronicCareManagementComponent;

  signNoteMenuItems: MenuItem[] = [];

  showAvsGuidanceComponent: Observable<boolean>;
  enableNoteDeletion$: Observable<boolean>;
  coSignSearchItems: Observable<Array<any>>;
  loading$: Observable<boolean>;
  workspaceExpanded$: Observable<boolean>;
  procedureSuggestions$: Observable<ProcedureSuggestion[]>;
  visitProcedure$: Observable<VisitProcedure>;
  patient$: Observable<Patient>;

  unsignedSummaryForm: UntypedFormGroup;
  unsignedSummaryFormModel: FormModel;
  messages = {
    alreadySigned: 'Summary is already signed and cannot be edited.',
    noteSigned: 'Note signed',
    cosignTaskCreated: 'Note signed and co-sign task created',
    appointmentNeedsToBeStarted:
      'Note cannot be signed until the appointment is started',
    unableToSignNote: 'Unable to sign note',
  };
  showButtonChoice = false;
  startVisitButtonPressed = false;
  showSubjectError = false;
  showConfirmation = false;
  showStartVisitButton = false;
  showServiceTicketDeleteError = false;
  featureDeleteOfficeNote = false;
  errorMsg: string;
  formSubmitError: boolean;
  isAttachable: boolean;
  basicPatientDetails: string;
  validationRules: MeasurementValidationRules;
  validationRanges: VitalsValidationRanges;

  get subjectError() {
    return (
      (!this.unsignedSummaryForm.get('subject').value ||
        this.unsignedSummaryForm.get('subject').value === '') &&
      this.showSubjectError
    );
  }

  get timeBasedVisitLabel() {
    const duration =
      this.summary.appointment && this.summary.appointment.duration
        ? this.summary.appointment.duration + ' min '
        : '';
    return `>50% of this ${duration}visit was spent on counseling & care coordination`;
  }

  get showHealthBackground() {
    return (
      (this.summary.healthBackground &&
        this.summary.healthBackground.familyData &&
        this.summary.healthBackground.familyData.notes) ||
      (this.summary.healthBackground &&
        this.summary.healthBackground.healthBackgroundHistoryId) ||
      (this.summary.healthBackground &&
        this.summary.healthBackground.illnessesAndSurgeries &&
        this.summary.healthBackground.illnessesAndSurgeries.notes) ||
      (this.summary.healthBackground &&
        this.summary.healthBackground.socialData &&
        this.summary.healthBackground.socialData.notes)
    );
  }

  get enableNoteDeletion() {
    return (
      !this.summary.signed &&
      !this.summaryIsWalkinVisit &&
      (!this.summaryIsOfficeVisit || this.featureDeleteOfficeNote) &&
      (!this.summaryIsMentalHealthVisit || this.featureDeleteOfficeNote)
    );
  }

  get commentable(): Commentable {
    return {
      ...this.summary.note,
      commentableType: 'summary',
    };
  }

  get summaryIsWalkinVisit(): Boolean {
    return isWalkinVisit(this.summary.noteType);
  }

  get summaryIsOfficeVisit(): Boolean {
    return isOfficeVisit(this.summary.noteType);
  }

  get summaryIsMentalHealthVisit(): Boolean {
    return isMentalHealthVisit(this.summary.noteType);
  }

  private patientId: number;
  private unsubscribe = new Subject<void>();
  private vitalSetFormService: VitalSetFormService = new VitalSetFormService();

  constructor(
    private patientChartSelectors: PatientChartSelectors,
    private patientChartActions: PatientChartActions,
    private patientSelectors: PatientSelectors,
    private summariesApi: SummariesApiService,
    private summariesActions: SummariesActions,
    private summariesSelectors: SummariesSelectors,
    private toastMessageService: ToastMessageService,
    private featureFlagSelectors: FeatureFlagSelectors,
    private analyticsActions: AnalyticsActions,
    private procedureSuggestionSelectors: ProcedureSuggestionSelectors,
    private s3Service: S3Service,
    private dialogService: DialogService,
    private visitProcedureSelectors: VisitProcedureSelectors,
    private visitProcedureActions: VisitProcedureActions,
    private launchDarklyService: LaunchDarklyService,
    private referenceDataSelectors: ReferenceDataSelectors,
  ) {}

  ngOnInit() {
    this.loadFeatureFlags();
    this.patient$ = this.patientSelectors.patient;
    this.getCurrentAppointment();

    this.patient$.pipe(filterTruthy()).subscribe((patient: Patient) => {
      this.basicPatientDetails = basicPatientDetails(patient);
      this.patientId = patient.id;
    });
    if (this.summary.visitProcedureId) {
      this.visitProcedureActions.getById({ id: this.summary.visitProcedureId });
    }
    this.setupForm();
    this.loadSelectors();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    if (this.unsignedSummaryFormModel) {
      this.unsignedSummaryFormModel.dispose();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.summary && !changes.summary.firstChange) {
      const currentValueId =
        changes.summary.currentValue && changes.summary.currentValue.id
          ? changes.summary.currentValue.id
          : null;
      const previousValueId =
        changes.summary.previousValue && changes.summary.previousValue.id
          ? changes.summary.previousValue.id
          : null;

      if (
        currentValueId &&
        previousValueId &&
        currentValueId !== previousValueId
      ) {
        this.setupForm();
      }

      this.setupProviderGuidance();
    }
  }

  onCommentUpdate(update: 'add' | 'remove') {
    this.summariesActions.updateSummarySuccess({
      ...this.summary,
      note: {
        ...this.summary.note,
        totalComments:
          update === 'add'
            ? this.summary.note.totalComments + 1
            : this.summary.note.totalComments - 1,
      },
    });
  }

  onSignSummary() {
    if (this.anyInvalidNonFormFields()) {
      return;
    }

    if (this.unsignedSummaryForm.valid) {
      this.showConfirmation = true;
      this.coSignCollapseRef.toggle();
    } else {
      this.unsignedSummaryForm.updateValueAndValidity();
    }
  }

  onDeleteSummary() {
    this.deleteSummary();
  }

  toggleServiceTicket() {
    this.workspaceExpanded$
      .pipe(take(1))
      .subscribe(expanded =>
        this.patientChartActions.expandWorkspace(!expanded),
      );
  }

  onUnlinkSection(type, extra = null) {
    if (type === 'Assessment-Plan') {
      const assessedProblems = Object.assign(
        [],
        this.summary.assessedProblems,
      ) as SummaryProblem[];
      const index = assessedProblems.findIndex(
        element => element.id === extra.id,
      );
      if (index > -1) {
        assessedProblems.splice(index, 1);
      }
      extra = {
        assessed_problem_history_ids: [
          ...assessedProblems.map(problem => problem.problemHistoryId),
        ],
      };
    } else if (type === 'Problems') {
      extra = { ...extra, medical_history: false };
    }

    this.summariesActions.unlink({
      patientId: this.patientId,
      type,
      extraFields: extra,
    });
  }

  removeServiceTicketItems(serviceTicketItem) {
    this.summariesActions.deleteServiceTicketItem({
      serviceTicketItemId: serviceTicketItem.id,
      summary: this.summary,
    });

    combineLatest([
      this.summariesSelectors.loading,
      this.summariesSelectors.error,
    ])
      .pipe(
        filter(([loading, _]) => !loading),
        map(([_, error]) => {
          if (error) {
            this.showServiceTicketDeleteError = true;
          }
        }),
        take(1),
      )
      .subscribe();
  }

  attachmentTrackBy(index: number, item): number {
    return item.id;
  }

  setCoSignAssignee(assignee) {
    this.coSignAssignee = assignee;
  }

  searchForCoSignNames(text: string) {
    if (text && text.length > 1) {
      this.coSignSearchItems = this.summariesApi
        .searchCoSign(text)
        .pipe(takeUntil(this.unsubscribe));
    }
  }

  filterTaskAssignees(term: string, item: any) {
    // return true so that all elasticsearch autocomplete results are displayed
    return true;
  }

  openVMTHelpModal() {
    const data: HelpRequestOptions = {
      patientId: this.patientId,
      linkedNoteId: this.summary.note.id,
      summary: {
        subject: this.summary.subject,
        subjective: this.summary.subjective,
      },
    };
    this.dialogService.open(HelpRequestConfirmationModalComponent, {
      autoFocus: true,
      data,
    });
  }

  trackFieldSelected(subcomponent: string): void {
    this.analyticsActions.trackEvent(AnalyticsEvent.FieldSelected, {
      workflow: 'Charting',
      component: this.summary.noteType.name,
      summaryId: this.summary.id,
      subcomponent,
      activeCharting: true,
    });
  }

  trackFieldUnselected(subcomponent: string): void {
    this.analyticsActions.trackEvent(AnalyticsEvent.FieldUnselected, {
      workflow: 'Charting',
      component: this.summary.noteType.name,
      summaryId: this.summary.id,
      subcomponent,
      activeCharting: true,
    });
  }

  trackHighPriorityClicked(highPriority: boolean): void {
    this.analyticsActions.trackEvent(AnalyticsEvent.HighPriorityClicked, {
      workflow: 'Charting',
      component: this.summary.noteType.name,
      summaryId: this.summary.id,
      subcomponent: 'High Priority Checkbox',
      highPriority,
      activeCharting: true,
    });
  }

  private setupForm() {
    if (this.unsignedSummaryFormModel) {
      this.unsignedSummaryFormModel.dispose();
    }

    const validationRules$ = this.referenceDataSelectors.get<
      MeasurementValidationRules
    >(ReferenceDataKeys.measurementValidationRules);

    const rangeValidationEnabled$ = this.launchDarklyService.variation$<
      boolean
    >(FeatureFlagNames.medsVitalsRangeValidation, false);

    const validationRanges$ = rangeValidationEnabled$.pipe(
      switchMap(enabled =>
        enabled
          ? this.referenceDataSelectors.get<VitalsValidationRanges>(
              ReferenceDataKeys.vitalsValidationRanges,
            )
          : of(null),
      ),
    );

    const isMinor$ = this.patientSelectors.isMinor;

    combineLatest([
      validationRules$,
      validationRanges$,
      rangeValidationEnabled$,
      isMinor$,
    ])
      .pipe(
        take(1),
        map(
          ([
            validationRules,
            validationRanges,
            rangeValidationEnabled,
            isMinor,
          ]) => {
            this.validationRules = validationRules;
            return this.vitalSetFormService.buildForm(
              this.summary.vitals,
              validationRules,
              rangeValidationEnabled,
              isMinor,
              validationRanges,
            );
          },
        ),
      )
      .subscribe(vitalSetForm => {
        this.unsignedSummaryFormModel = new FormModel(
          mapSummaryToUnsignedForm(this.summary, vitalSetForm),
          {
            saveFunction: this.saveUnsignedSummary.bind(this),
            saveInvalid: true,
          },
        );

        this.unsignedSummaryForm = this.unsignedSummaryFormModel.form;
      });

    this.isAttachable =
      this.summary.summaryProviderRecommendation &&
      !this.summary.summaryProviderRecommendation.published;

    this.signNoteMenuItems = this.setupSignNoteMenuItems();

    this.setupProviderGuidance();
  }

  private setupProviderGuidance() {
    const avsVisible =
      this.summary.appointment && this.summary.appointment.avs_visible;

    this.showAvsGuidanceComponent = of(avsVisible);
  }

  setupSignNoteMenuItems() {
    if (!this.summaryIsWalkinVisit) {
      return [
        {
          label: 'Sign and Request Co-signature',
          command: () => {
            this.coSignCollapseRef.toggle();
            this.showConfirmation = false;
          },
        },
      ];
    } else {
      return null;
    }
  }

  signSummary() {
    if (this.anyInvalidNonFormFields()) {
      return;
    }

    const sys = this.summary.vitals[vitalTypes.systolicPressure].value;
    const dia = this.summary.vitals[vitalTypes.diastolicPressure].value;
    const noneEntered = sys === null && dia === null;
    const bothEntered = sys !== null && dia !== null;
    if (!noneEntered && !bothEntered) {
      return;
    }

    const cosignTaskAssignee = this.coSignAssignee || {};

    this.summariesActions.signSummary({
      id: this.summary.id,
      changes: {
        patientId: this.patientId,
        summaryId: this.summary.id,
        summary: { ...this.summary, cosignTaskAssignee },
      },
    });

    this.summariesSelectors.loading
      .pipe(
        filter(loading => !loading),
        withLatestFrom(this.summariesSelectors.error),
        tap(([_, error]) => {
          if (!error) {
            this.handleSignSummarySuccess();
          }
        }),
        take(1),
      )
      .subscribe();
  }

  onAddAttachment(file: File) {
    const key = createAttachmentKey(this.patientId, file.name);
    const s3Pointer = { title: file.name, contentLength: file.size };

    this.s3Service
      .upload(key, file)
      .pipe(take(1))
      .subscribe(
        data => {
          this.attachmentCompleted(s3Pointer, data);
        },
        err => {
          this.attachmentFailed(err);
        },
      );
  }

  attachmentCompleted(s3Pointer: any, s3Data: any) {
    const existing =
      this.summary.summaryProviderRecommendation.s3Pointers || [];
    const alreadyExists = existing.find(attachment => {
      return attachment.title === s3Pointer.title;
    });

    if (!alreadyExists) {
      existing.push({
        ...s3Pointer,
        bucket: s3Data.Bucket,
        key: s3Data.Key,
      } as any);

      this.updateSummaryProviderRecommendationAttachments(existing);
    }
  }

  updateSummaryProviderRecommendationAttachments(attachments) {
    this.summariesActions.updateSummaryProviderRecommendationAttachments({
      id: this.summary.id,
      changes: {
        patientId: this.patientId,
        summaryId: this.summary.id,
        data: mapSummaryToSummaryProviderRecommendationUpdateData(
          this.summary,
          attachments,
        ),
      },
    });
  }

  attachmentFailed(err: any) {
    this.errorMsg = `Upload failed: ${err.message}`;
    this.formSubmitError = true;
  }

  onDeleteAttachment(attachment: S3Pointer) {
    this.s3Service
      .delete(attachment.key, attachment.bucket)
      .pipe(take(1))
      .subscribe(() => {
        let existing =
          this.summary.summaryProviderRecommendation.s3Pointers || [];
        existing = existing.filter(pt => !pt.destroy);
        const s3PointerAttachment = existing.find(a => a.id === attachment.id);
        s3PointerAttachment.destroy = true;

        this.updateSummaryProviderRecommendationAttachments(existing);
      });
  }

  openCosignRequestForm() {
    this.coSignCollapseRef.toggle();
    this.showConfirmation = false;
  }

  anyInvalidNonFormFields(): boolean {
    // chronic care form is not included in the summary form, so we
    // need to touch the form to trigger validations
    if (this.chronicCareComponent) {
      this.chronicCareComponent.markChronicCareFormTouched();
    }

    if (this.summarySubjectInvalid) {
      this.showSubjectError = true;
    }

    return this.summarySubjectInvalid || !this.chronicCareManagementFormValid;
  }

  startVisitFromNote(appointmentId: number) {
    this.summariesActions.startVisitFromNote({
      appointmentId: appointmentId,
      event: 'start_appointment',
    });
    this.startVisitButtonPressed = true;
  }

  getAppointmentFailure(err: any) {
    this.errorMsg = `Retrieving current appointment has failed: ${err.message}`;
    this.formSubmitError = true;
  }

  private getCurrentAppointment() {
    this.summariesApi.getCurrentAppointment(this.summary.id).subscribe(
      summaryAppointment => {
        if (summaryAppointment) {
          this.showButtonChoice = true;
        }
        this.showStartVisitButton = summaryAppointment?.state === 'arrived';
      },
      err => {
        this.getAppointmentFailure(err);
      },
    );
  }

  private get summarySubjectInvalid(): boolean {
    if (!this.summary.subject || this.summary.subject === '') {
      return true;
    }
    return false;
  }

  get chronicCareManagementFormValid() {
    const hasChronicCareForm =
      this.summary.hasProgramVisit && this.chronicCareComponent;

    return hasChronicCareForm
      ? this.chronicCareComponent.chronicCareFormValid
      : true;
  }

  private handleSignSummarySuccess(): void {
    if (this.todo.name === 'Co-Sign') {
      this.trackSummarySigned(true);
      this.toastMessageService.add({
        severity: 'success',
        detail: this.messages.cosignTaskCreated,
      });
    } else {
      this.trackSummarySigned(false);
      this.toastMessageService.add({
        severity: 'success',
        detail: this.messages.noteSigned,
      });
    }
  }

  private trackSummarySigned(cosignatureRequested: boolean) {
    this.analyticsActions.trackEvent(AnalyticsEvent.SummarySigned, {
      workflow: 'Charting',
      component: this.summary.noteType.name,
      subcomponent: 'Sign Button',
      summaryId: this.summary.id,
      cosignatureRequested,
    });
  }

  private loadSelectors() {
    this.loading$ = this.summariesSelectors.loading;
    this.workspaceExpanded$ = this.patientChartSelectors.workspaceExpanded;
    this.procedureSuggestions$ = this.procedureSuggestionSelectors.getBySummaryId(
      this.summary.id,
    );
    this.visitProcedure$ = this.visitProcedureSelectors.getById(
      this.summary.visitProcedureId,
    );
  }

  private loadFeatureFlags() {
    this.launchDarklyService
      .variation$(FeatureFlagNames.new1lifeEnableDeletingOfficeNote, false)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(featureDeleteOfficeNote => {
        this.featureDeleteOfficeNote = featureDeleteOfficeNote;
      });
  }

  private deleteSummary() {
    const message = 'Are you sure you want to delete this note?';
    const isConfirmed = this.getConfirmation(message);

    if (!isConfirmed) {
      return;
    }

    this.summariesActions.deleteSummary({
      patientId: this.patientId,
      summaryId: this.summary.id,
    });
  }

  private getConfirmation(message: string): boolean {
    // Native window confirm box will be replaced in future
    if (typeof confirm === 'function') {
      return !!confirm(message);
    }

    return false;
  }

  private saveUnsignedSummary(formValues) {
    const { observer, complete } = proxyWith();

    const data = mapUnsignedFormToSummaryUpdate(formValues, this.summary);
    this.summariesActions.updateSummary({
      id: this.summary.id,
      changes: {
        patientId: this.patientId,
        summaryId: this.summary.id,
        data,
      },
    });

    this.summariesSelectors.loading
      .pipe(
        filter(loading => !loading),
        withLatestFrom(
          this.summariesSelectors.error,
          this.summariesSelectors.summaries,
        ),
        take(1),
      )
      .subscribe(([_, error, entities]) => {
        complete(entities[this.summary.id], error);
      });

    return observer;
  }
}
