import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';

import { AnalyticsActions } from '@app/core';
import {
  AnalyticsEvent,
  TrackEventProperties,
} from '@app/core/analytics/shared/analytics.type';
import { DefaultAnalyticsProps } from '@app/core/analytics/shared/default-analytics-props';
import { Todo } from '@app/modules/todo/shared/todo.type';
import { AutoCompleteComponent } from '@app/shared';
import { ToastMessageService } from '@app/shared/components/toast';

import { TaskAssignmentService } from '../../shared/task-assignment.service';
import { TodoActions } from '../../store/todo.actions';

@Component({
  selector: 'omg-task-assignment',
  templateUrl: './task-assignment.component.html',
  styleUrls: ['./task-assignment.component.scss'],
})
export class TaskAssignmentComponent {
  @Input() todo: Observable<Todo>;
  @Input()
  assigneeFieldAnalyticsEventProps: Partial<TrackEventProperties>;
  @Input()
  priorityCheckboxAnalyticsEventProps: Partial<TrackEventProperties>;
  @Input()
  highPriorityEventProps: Partial<TrackEventProperties>;

  @Output() assigneeUpdated = new EventEmitter<string>();
  @Output() focusOn = new EventEmitter<string>();
  @Output() focusOut = new EventEmitter<string>();
  @Output() clicked = new EventEmitter<any>();

  @ViewChild('assigneeSearch')
  private assigneeSearchRef: AutoCompleteComponent;

  searchResults: Observable<Array<any>>;
  isHighPriority = false;

  constructor(
    private taskAssignmentService: TaskAssignmentService,
    private toastMessageService: ToastMessageService,
    private todoActions: TodoActions,
    private analyticsActions: AnalyticsActions,
  ) {}

  onSearch(text: string) {
    if (text.length > 1) {
      this.searchResults = this.taskAssignmentService.searchTaskAssignees(text);
    }
  }

  clearAssignee() {
    this.assigneeSearchRef.writeValue(null); // Set the value to null to clear it; the dropdown won't open since the items have been reset
  }

  restoreAssignee(todo) {
    if (todo.assignee !== null) {
      this.assigneeSearchRef.writeValue(todo.assignee);
    }
  }

  onAssigneeChange(todo: Todo): void {
    this.updateAssignee(todo, true);
    if (this.assigneeFieldAnalyticsEventProps) {
      this.analyticsActions.trackEvent(AnalyticsEvent.FieldSelected, {
        ...this.assigneeFieldAnalyticsEventProps,
      });
    }
  }

  onPriorityChange(todo: Todo): void {
    this.updateAssignee(todo, false);
    this.clicked.emit();
    // Heri, Nov 28, 2022
    // adding this to keep track of highPriority click event until the bug mentioned below gets fixed
    this.isHighPriority = !this.isHighPriority;

    // Benjamin Nov 2022:
    // there's a bug where the todo.highPriority value is always false (at least in development mode)
    // because the checkbox model tries to modify the todo input observable value.
    // console error: "Cannot assign to read only property 'highPriority' of object '[object Object]'"
    // fixing this bug is outside of the scope of CQ-1189 so leaving as is for now.
    if (this.priorityCheckboxAnalyticsEventProps) {
      this.analyticsActions.trackEvent(AnalyticsEvent.HighPriorityClicked, {
        ...this.priorityCheckboxAnalyticsEventProps,
        isHighPriority: todo.highPriority,
      });
    }

    if (this.highPriorityEventProps) {
      this.analyticsActions.trackEvent(AnalyticsEvent.HighPriorityClicked, {
        ...DefaultAnalyticsProps,
        ...this.highPriorityEventProps,
        highPriority: this.isHighPriority,
      });
    }
  }

  updateAssignee(todo: Todo, reassign: Boolean = true) {
    if (todo.assignee !== null) {
      this.todoActions.updateTodo(todo);
      this.assigneeSearchRef.resetItems(); // Reset after updating the model so that the dropdown doesn't open on click
      if (reassign) {
        this.assigneeUpdated.emit(todo.assignee.identifier);
        this.toastMessageService.add({
          severity: 'success',
          detail: 'Task reassigned',
        });
      }
    }
  }

  filterTaskAssignees(term: string, item: any) {
    // return true so that all elasticsearch autocomplete results are displayed
    return true;
  }

  focus(subcomponent: string): void {
    this.focusOn.emit(subcomponent);
  }

  focusout(subcomponent: string): void {
    this.focusOut.emit(subcomponent);
  }
}
