import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { AppState } from '@app/core/store/store/app-store.reducer';
import { requiredAfterTrim } from '@app/features/procedure-interaction/components/procedure-interaction/procedure-interaction.component';
import { ProcedureInteraction } from '@app/features/procedure-interaction/shared/procedure-interaction.type';
import { updateProcedureInteraction } from '@app/features/procedure-interaction/store/procedure-interaction.actions';
import { ErrorMessageFn } from '@app/shared/components/control-errors/control-errors.component';

@Component({
  selector: 'omg-procedure-interaction-summary',
  templateUrl: './procedure-interaction-summary.component.html',
  styleUrls: ['./procedure-interaction-summary.component.scss'],
})
export class ProcedureInteractionSummaryComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() procedureInteraction: Pick<
    ProcedureInteraction,
    'id' | 'procedureSummary' | 'procedureType' | 'state'
  >;
  @Input() hideTitle?: boolean;

  @Input() procedureSummaryControl: UntypedFormControl;

  // Handles cleaning up subscriptions when the component is destroyed
  private unsubscribe = new Subject<void>();

  constructor(private store: Store<AppState>) {}

  // Set up a subscription to automatically send the update action whenever
  // the procedure summary text changes, debounced by 3 seconds and only when
  // the text is different from before.
  ngOnInit(): void {
    // This is coming in as null from `procedure-suggestion-procedure-interaction-form.component.html`
    if (!this.procedureSummaryControl) {
      this.procedureSummaryControl = new UntypedFormControl('', [
        requiredAfterTrim,
      ]);
    }

    this.procedureSummaryControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe(procedureSummary => {
        procedureSummary = procedureSummary?.trim() || null;
        if (procedureSummary === this.procedureInteraction.procedureSummary) {
          return;
        }

        this.store.dispatch(
          updateProcedureInteraction({
            id: this.procedureInteraction.id,
            procedureSummary,
          }),
        );
      });
  }

  // The first time procedure interaction is loaded, we update the textbox with
  // the summary text. Updating the textbox on any other changes will mess up
  // the cursor position as updates to the summary will trigger a new change to
  // procedureInteraction.
  ngOnChanges({ procedureInteraction }: SimpleChanges) {
    if (procedureInteraction.isFirstChange()) {
      this.procedureSummaryControl.setValue(
        this.procedureInteraction.procedureSummary,
      );
    }
  }

  // When the textbox loses focus, we send an update request to capture any
  // changes that might have been missed. See the html template for where this
  // is called.
  onBlur() {
    const value = this.procedureSummaryControl.value?.trim() || null;
    if (value === this.procedureInteraction.procedureSummary) {
      return;
    }

    this.store.dispatch(
      updateProcedureInteraction({
        id: this.procedureInteraction.id,
        procedureSummary: value,
      }),
    );
  }

  get readOnly(): boolean {
    return this.procedureInteraction?.state !== 'draft';
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  toErrorMessage: ErrorMessageFn = (key, details) => {
    switch (key) {
      case 'required':
        return `Please add a procedure summary`;
    }
  };
}
