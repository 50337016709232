import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { TaskTemplate, Todo } from '@app/modules/todo/shared/todo.type';
import { TodoActions } from '@app/modules/todo/store/todo.actions';
import { DropdownItem } from '@app/shared';

@Component({
  selector: 'omg-task-template-selector',
  templateUrl: './task-template-selector.component.html',
  styleUrls: ['./task-template-selector.component.scss'],
})
export class TaskTemplateSelectorComponent implements OnInit, OnDestroy {
  @Input() todo$: Observable<Todo>;
  @Input() inProgress: boolean;
  taskTemplateTypes: DropdownItem[] = [];
  showDropdown: boolean = false;
  form: FormGroup;
  todo: Todo;

  private unsubscribe$ = new Subject<void>();

  constructor(private todoActions: TodoActions) {}

  ngOnInit(): void {
    this.todo$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(todo => {
          this.todo = todo;
          this.showDropdown = false;
          this.taskTemplateTypes = this.mapTaskTemplateTypes(
            todo.relatedTaskTemplates,
          );
        }),
      )
      .subscribe();

    this.form = new FormGroup<{ taskTemplateId: FormControl<number> }>({
      taskTemplateId: new FormControl<number>(this.todo?.taskTemplateId),
    });
  }

  toggleDropdown() {
    this.showDropdown = true;
  }

  mapTaskTemplateTypes(relatedTaskTemplates: TaskTemplate[]): DropdownItem[] {
    return relatedTaskTemplates.map(template => ({
      label: template.name,
      value: template.id,
    }));
  }

  updateTodo() {
    this.todoActions.updateTodo({
      ...this.todo,
      taskTemplateId: this.form.controls.taskTemplateId.value,
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
