<div class="pdmp-tab-contents-container">
  <div class="pdmp-report-info">
    <button type="button"
            omgButton
            variant="primary"
            class="overlay-top">
      Open in a new tab
      <i class="fa-regular fa-arrow-up-right-from-square"></i>
    </button>
    <p>Report for CA</p>
    <p class="reports-unavailable-text">
      Reporting not available in IL / MD / NE / NH / NY / VT / GUAM
    </p>
    <p class="last-viewed-by-text">
      Last viewed by Kiran Ivan at 8:54am on 6/22/2022
    </p>
  </div>
  <div class="report-container">
    <ng-template [ngTemplateOutlet]="loadingPdmpReport">
    </ng-template>
  </div>
</div>

<ng-template #loadingPdmpReport>
  <p class="display-text">
    Report will display here
  </p>
  <i class="fa-regular fa-spinner fa-spin-pulse"></i>
</ng-template>
