<div [formGroup]="form">
  <ng-container *ngIf="(vitalsRangeValidationFlag$ | ngrxPush); else legacyVitalsGrid">
    <div class="vitals-range-text">
      Standard set - {{ isInfant ? 'infant' : isMinor ? 'minor' : 'adult' }}
      <omg-popover #vitalRangePopover
                   [enabled]="true"
                   height="127"
                   placement="top"
                   width="390">
        <div class="padding-normal"
             om-layout="vertical"
             om-layout-gutter>
          <strong>Vitals Sets</strong>
          <div>Vitals are validated against standard ranges for the patient's age.</div>
          <a href="https://docs.google.com/spreadsheets/d/1KqltjQRWscBc0vxGDdLZ7eTfB5g0qBmo4ObtN2AOC0U"
             rel="noopener noreferrer"
             target="_blank">
            View Ranges
          </a>
        </div>
      </omg-popover>
      <span *ngIf="!isMinor"
            [omgPopoverTriggerFor]="vitalRangePopover"
            class="fa clickable fa-exclamation-circle"
            triggerOn="click"
            omgTooltip
            tooltipPosition="right"></span>
    </div>

    <div class="-immediate-errors"
         om-layout-gutter-large>
      <div *ngIf="isInfant; then infantVitalsGridTemplate"></div>
      <ng-container *ngTemplateOutlet="cardiacVitalsGridTemplate"></ng-container>
      <div *ngIf="!isInfant; then adultVitalsGridTemplate"></div>
      <ng-container *ngTemplateOutlet="sharedVitalsGridTemplate"></ng-container>
    </div>
  </ng-container>

  <ng-template #showErrorMessage
               let-errorMessage="errorMessage"
               let-isAfterUnit="isAfterUnit">
    <i class="fa fa-exclamation-triangle"
       [ngClass]="isAfterUnit ? 'exclamation-triangle-after-unit': 'exclamation-triangle'"></i>
    <div class="error-message"> {{ errorMessage }} </div>
  </ng-template>

  <ng-template #showWarningFlag
               let-warningMessage="warningMessage"
               let-isAfterUnit="isAfterUnit"
               let-isHeartRate="isHeartRate">
    <i class="fa fa-flag"
       [ngClass]="{'flag-after-unit': isAfterUnit, 'flag': !isAfterUnit, 'heart-rate-flag': isHeartRate}"
       omgTooltip
       tooltipText="{{ warningMessage }}"
       tooltipPosition="bottom"></i>
  </ng-template>

  <ng-template #infantVitalsGridTemplate>
    <div class="vitals-grid">
      <label class="-stacked">
        {{ weight.label }}
        <label class="plain-text"
               om-layout
               om-layout-align="center center"
               om-layout-gutter-mini>
          <input [control]="form.get(weight.key)"
                 [name]="weight.key"
                 [step]="weight.step"
                 data-cy="notes-vitals-wt"
                 om-layout-fill
                 omgInputNumber
                 omgSyncInput
                 type="number"
                 [ngClass]="{ 'error': form.get(weight.key).errors }" />
          <span>{{ weight.unit }}</span>
        </label>
        <ng-template *ngIf="form.get(weight.key).errors"
                     [ngTemplateOutlet]="showErrorMessage"
                     [ngTemplateOutletContext]="{ errorMessage: 'Out of range', isAfterUnit: true }">
        </ng-template>
      </label>
      <span class="padding-half-vertical"
            om-layout
            om-layout-align="start end">
        <div *ngIf="ordinalizedWeightPercentile$ | ngrxPush as weightPercentile"
             om-layout
             om-layout-gutter-large>
          <span>|</span>
          <span>{{ weightPercentile }}</span>
        </div>
      </span>

      <label class="-stacked">
        Length
        <label class="plain-text"
               om-layout
               om-layout-align="center center"
               om-layout-gutter-mini>
          <input [control]="form.get('height')"
                 name="height"
                 om-layout-fill
                 omgInputNumber
                 omgSyncInput
                 step="0.1"
                 type="number"
                 [ngClass]="{ 'error': form.get('height').errors }" />
          <span>in</span>
        </label>
        <ng-template *ngIf="form.get('height').errors"
                     [ngTemplateOutlet]="showErrorMessage"
                     [ngTemplateOutletContext]="{ errorMessage: 'Out of range', isAfterUnit: true }">
        </ng-template>
      </label>
      <span class="padding-half-vertical"
            om-layout
            om-layout-align="start end">
        <div *ngIf="ordinalizedHeightPercentile$ | ngrxPush as heightPercentile"
             om-layout
             om-layout-gutter-large>
          <span>|</span>
          <span>{{ heightPercentile }}</span>
        </div>
      </span>

      <ng-template [ngTemplateOutlet]="headCircumferenceVital">
      </ng-template>
    </div>
  </ng-template>

  <ng-template #adultVitalsGridTemplate>
    <div class="vitals-grid">
      <label class="-stacked">
        {{ weight.label }}
        <label class="plain-text"
               om-layout
               om-layout-align="center center"
               om-layout-gutter-mini>
          <input [control]="form.get(weight.key)"
                 [name]="weight.key"
                 [step]="weight.step"
                 om-layout-fill
                 data-cy="notes-vitals-wt"
                 omgInputNumber
                 omgSyncInput
                 type="number"
                 [ngClass]="{ 'error': form.get('weight').errors }" />
          <span>{{ weight.unit }}</span>
        </label>
        <ng-template *ngIf="form.get('weight').errors"
                     [ngTemplateOutlet]="showErrorMessage"
                     [ngTemplateOutletContext]="{ errorMessage: 'Out of range', isAfterUnit: true }">
        </ng-template>
        <ng-template *ngIf="!isMinor && form.get('weight').warnings"
                     [ngTemplateOutlet]="showWarningFlag"
                     [ngTemplateOutletContext]="{ warningMessage: form.get('weight').warnings.warningMessage, isAfterUnit: true }">
        </ng-template>
      </label>
      <span *ngIf="isMinor"
            class="padding-half-vertical"
            om-layout
            om-layout-align="start end">
        <div *ngIf="ordinalizedWeightPercentile$ | ngrxPush as weightPercentile"
             om-layout
             om-layout-gutter-large>
          <span>|</span>
          <span>{{ weightPercentile }}</span>
        </div>
      </span>

      <label class="-stacked">
        ht
        <label class="plain-text"
               om-layout
               om-layout-align="center center"
               om-layout-gutter-mini>
          <input [control]="form.get('height')"
                 name="height"
                 om-layout-fill
                 data-cy="notes-vitals-ht"
                 omgInputNumber
                 omgSyncInput
                 step="0.1"
                 type="number"
                 [ngClass]="{ 'error': form.get('height').errors }" />
          <span>in</span>
        </label>
        <ng-template *ngIf="form.get('height').errors"
                     [ngTemplateOutlet]="showErrorMessage"
                     [ngTemplateOutletContext]="{ errorMessage: 'Out of range', isAfterUnit: true }">
        </ng-template>
        <ng-template *ngIf="!isMinor && form.get('height').warnings"
                     [ngTemplateOutlet]="showWarningFlag"
                     [ngTemplateOutletContext]="{ warningMessage: form.get('height').warnings.warningMessage, isAfterUnit: true }">
        </ng-template>
      </label>
      <span *ngIf="isMinor"
            class="padding-half-vertical"
            om-layout
            om-layout-align="start end">
        <div *ngIf="ordinalizedHeightPercentile$ | ngrxPush as heightPercentile"
             om-layout
             om-layout-gutter-large>
          <span>|</span>
          <span>{{ heightPercentile }}</span>
        </div>
      </span>

      <label class="-stacked">
        BMI
        <input [control]="form.get('bodyMassIndex')"
               [value]="form.get('bodyMassIndex').value"
               class="om-input -readonly"
               name="bodyMassIndex"
               omgSyncInput
               readonly
               type="number" />
      </label>
      <span *ngIf="ordinalizedBmiPercentile$ | ngrxPush as bmiPercentile"
            class="padding-half-vertical"
            om-layout
            om-layout-align="start end">
        <div om-layout
             om-layout-gutter-large>
          <span>|</span>
          <span>{{ bmiPercentile }}</span>
        </div>
      </span>

      <ng-template *ngIf="displayChildHeadCircForThreeAndUnder$ | ngrxPush"
                   [ngTemplateOutlet]="headCircumferenceVital">
      </ng-template>
    </div>

    <div *ngIf="form.get('bodyMassIndex').invalid"
         class="om-messages">BMI must be in range 0-99</div>
  </ng-template>

  <ng-template #cardiacVitalsGridTemplate>
    <div class="vitals-grid">
      <label class="-stacked">
        sys
        <input [control]="form.get('systolicPressure')"
               [ngClass]="{'error': form.get('systolicPressure').errors || form.errors?.systolicPressureConditionallyRequired }"
               name="systolicPressure"
               omgInputNumber
               omgSyncInput
               step="1"
               type="number" />
        <ng-template *ngIf="form.get('systolicPressure').errors"
                     [ngTemplateOutlet]="showErrorMessage"
                     [ngTemplateOutletContext]="{ errorMessage: 'Out of range' }">
        </ng-template>
        <ng-template *ngIf="form.errors?.systolicPressureConditionallyRequired"
                     [ngTemplateOutlet]="showErrorMessage"
                     [ngTemplateOutletContext]="{ errorMessage: 'Required' }">
        </ng-template>
        <ng-template *ngIf="!isMinor && form.get('systolicPressure').warnings"
                     [ngTemplateOutlet]="showWarningFlag"
                     [ngTemplateOutletContext]="form.get('systolicPressure').warnings"></ng-template>
      </label>

      <label class="-stacked">
        dia
        <input [control]="form.get('diastolicPressure')"
               [ngClass]="{ 'error': form.get('diastolicPressure').errors || form.errors?.diastolicPressureConditionallyRequired}"
               name="diastolicPressure"
               omgInputNumber
               omgSyncInput
               step="1"
               type="number" />
        <ng-template *ngIf="form.get('diastolicPressure').errors"
                     [ngTemplateOutlet]="showErrorMessage"
                     [ngTemplateOutletContext]="{ errorMessage: 'Out of range' }">
        </ng-template>
        <ng-template *ngIf="form.errors?.diastolicPressureConditionallyRequired"
                     [ngTemplateOutlet]="showErrorMessage"
                     [ngTemplateOutletContext]="{ errorMessage: 'Required' }">
        </ng-template>
        <ng-template *ngIf="!isMinor && form.get('diastolicPressure').warnings"
                     [ngTemplateOutlet]="showWarningFlag"
                     [ngTemplateOutletContext]="form.get('diastolicPressure').warnings"></ng-template>
      </label>

      <label class="-stacked">
        HR
        <input [control]="form.get('heartRate')"
               name="heartRate"
               omgInputNumber
               omgSyncInput
               step="1"
               type="number"
               [ngClass]="{ 'error': form.get('heartRate').errors}" />
        <ng-template *ngIf="form.get('heartRate').errors"
                     [ngTemplateOutlet]="showErrorMessage"
                     [ngTemplateOutletContext]="{ errorMessage: 'Out of range'}">
        </ng-template>
        <ng-template *ngIf="!isMinor && form.get('heartRate').warnings"
                     [ngTemplateOutlet]="showWarningFlag"
                     [ngTemplateOutletContext]="{ warningMessage: form.get('heartRate').warnings.warningMessage, isHeartRate: true }">
        </ng-template>
      </label>
      <label class="-stacked">
        RR
        <input [control]="form.get('respiratoryRate')"
               name="respiratoryRate"
               omgInputNumber
               omgSyncInput
               step="1"
               type="number"
               [ngClass]="{ 'error': form.get('respiratoryRate').errors }" />
        <ng-template *ngIf="form.get('respiratoryRate').errors"
                     [ngTemplateOutlet]="showErrorMessage"
                     [ngTemplateOutletContext]="{ errorMessage: 'Out of range' }">
        </ng-template>
        <ng-template *ngIf="!isMinor && form.get('respiratoryRate').warnings"
                     [ngTemplateOutlet]="showWarningFlag"
                     [ngTemplateOutletContext]="form.get('respiratoryRate').warnings"></ng-template>
      </label>
    </div>
  </ng-template>

  <ng-template #sharedVitalsGridTemplate>
    <div class="vitals-grid">
      <label class="-stacked">
        temp
        <label class="plain-text"
               om-layout
               om-layout-align="center center"
               om-layout-gutter-mini>
          <input [control]="form.get('temperature')"
                 name="temperature"
                 om-layout-fill
                 omgInputNumber
                 omgSyncInput
                 step="0.1"
                 type="number"
                 [ngClass]="{ 'error': form.get('temperature').errors }" />
          <span>°F</span>
        </label>
        <ng-template *ngIf="form.get('temperature').errors"
                     [ngTemplateOutlet]="showErrorMessage"
                     [ngTemplateOutletContext]="{ errorMessage: 'Out of range', isAfterUnit: true }">
        </ng-template>
        <ng-template *ngIf="!isMinor && form.get('temperature').warnings"
                     [ngTemplateOutlet]="showWarningFlag"
                     [ngTemplateOutletContext]="{ warningMessage: form.get('temperature').warnings.warningMessage, isAfterUnit: true }">
        </ng-template>
      </label>

      <label class="-stacked">
        SpO₂
        <label class="plain-text"
               om-layout
               om-layout-align="center center"
               om-layout-gutter-mini>
          <input [control]="form.get('pulseOximetry')"
                 name="pulseOximetry"
                 om-layout-fill
                 omgInputNumber
                 omgSyncInput
                 type="number"
                 [ngClass]="{ 'error': form.get('pulseOximetry').errors }" />
          <span>%</span>
        </label>
        <ng-template *ngIf="form.get('pulseOximetry').errors"
                     [ngTemplateOutlet]="showErrorMessage"
                     [ngTemplateOutletContext]="{ errorMessage: 'Out of range', isAfterUnit: true }">
        </ng-template>
        <ng-template *ngIf="!isMinor && form.get('pulseOximetry').warnings"
                     [ngTemplateOutlet]="showWarningFlag"
                     [ngTemplateOutletContext]="{ warningMessage: form.get('pulseOximetry').warnings.warningMessage, isAfterUnit: true }">
        </ng-template>
      </label>

      <label class="-stacked">
        FiO₂
        <label class="plain-text"
               om-layout
               om-layout-align="center center"
               om-layout-gutter-mini>
          <input [control]="form.get('oxygenConcentration')"
                 name="oxygenConcentration"
                 om-layout-fill
                 omgInputNumber
                 omgSyncInput
                 type="number"
                 [ngClass]="{ 'error': form.get('oxygenConcentration').errors }" />
          <span>%</span>
        </label>
        <ng-template *ngIf="form.get('oxygenConcentration').errors"
                     [ngTemplateOutlet]="showErrorMessage"
                     [ngTemplateOutletContext]="{ errorMessage: 'Out of range', isAfterUnit: true }">
        </ng-template>
        <ng-template *ngIf="!isMinor && form.get('oxygenConcentration').warnings"
                     [ngTemplateOutlet]="showWarningFlag"
                     [ngTemplateOutletContext]="{ warningMessage: form.get('oxygenConcentration').warnings.warningMessage, isAfterUnit: true }">
        </ng-template>
      </label>
    </div>
  </ng-template>

  <ng-template #legacyVitalsGrid>
    <!-- TODO: Do we need -immediate-errors here? -->
    <div *ngIf="validationRules"
         class="-immediate-errors"
         om-layout
         om-layout-gutter-large>
      <label class="-stacked"
             om-flex="15">
        sys
        <input [control]="form.get('systolicPressure')"
               [max]="validationRules.systolicPressure.max"
               [min]="validationRules.systolicPressure.min"
               [ngClass]="{ 'error': !form.get('systolicPressure').value && form.get('diastolicPressure').value }"
               name="systolicPressure"
               omgInputNumber
               omgSyncInput
               step="1"
               type="number"
               (focus)="focusTextBox.emit('Sys')"
               (focusout)="focusoutTextBox.emit('Sys')" />
      </label>

      <label class="-stacked"
             om-flex="15">
        dia
        <input [control]="form.get('diastolicPressure')"
               [max]="validationRules.diastolicPressure.max"
               [min]="validationRules.diastolicPressure.min"
               [ngClass]="{ 'error': !form.get('diastolicPressure').value && form.get('systolicPressure').value }"
               name="diastolicPressure"
               omgInputNumber
               omgSyncInput
               step="1"
               type="number"
               (focus)="focusTextBox.emit('Dia')"
               (focusout)="focusoutTextBox.emit('Dia')" />
      </label>

      <label class="-stacked"
             om-flex="15">
        HR
        <input [control]="form.get('heartRate')"
               [max]="validationRules.heartRate.max"
               [min]="validationRules.heartRate.min"
               name="heartRate"
               omgInputNumber
               omgSyncInput
               step="1"
               type="number"
               (focus)="focusTextBox.emit('HR')"
               (focusout)="focusoutTextBox.emit('HR')" />
      </label>

      <label class="-stacked"
             om-flex="15">
        RR
        <input [control]="form.get('respiratoryRate')"
               [max]="validationRules.respiratoryRate.max"
               [min]="validationRules.respiratoryRate.min"
               name="respiratoryRate"
               omgInputNumber
               omgSyncInput
               step="1"
               type="number"
               (focus)="focusTextBox.emit('RR')"
               (focusout)="focusoutTextBox.emit('RR')" />
      </label>

      <label class="-stacked"
             om-flex="15">
        temp
        <label om-layout
               om-layout-align="start center"
               om-layout-gutter-mini>
          <input [control]="form.get('temperature')"
                 [max]="validationRules.temperature.max"
                 [min]="validationRules.temperature.min"
                 name="temperature"
                 om-layout-fill
                 omgInputNumber
                 omgSyncInput
                 step="0.1"
                 type="number"
                 (focus)="focusTextBox.emit('Temp')"
                 (focusout)="focusoutTextBox.emit('Temp')" />
          <span>°F</span>
        </label>
      </label>
    </div>

    <div class="-immediate-errors"
         om-layout
         om-layout-gutter-large>
      <label class="-stacked"
             om-flex="15">
        {{ weight.label }}
        <label class="plain-text"
               om-layout
               om-layout-align="center center"
               om-layout-gutter-mini>
          <input [control]="form.get(weight.key)"
                 [name]="weight.key"
                 [step]="weight.step"
                 max="999"
                 min="2"
                 om-layout-fill
                 omgInputNumber
                 omgSyncInput
                 data-cy="notes-vitals-wt"
                 type="number"
                 (focus)="focusTextBox.emit('Wt')"
                 (focusout)="focusoutTextBox.emit('Wt')" />
          <span>{{ weight.unit }}</span>
        </label>
      </label>
      <span class="padding-half-vertical"
            om-flex="15"
            om-layout
            om-layout-align="start end">
        <div *ngIf="ordinalizedWeightPercentile$ | ngrxPush as weightPercentile"
             om-layout
             om-layout-gutter-large>
          <span om-flex="15">|</span>
          <span>{{ weightPercentile }}</span>
        </div>
      </span>

      <label class="-stacked"
             om-flex="15">
        ht
        <label class="plain-text"
               om-layout
               om-layout-align="center center"
               om-layout-gutter-mini>
          <input [control]="form.get('height')"
                 [max]="108"
                 [min]="0"
                 name="height"
                 om-layout-fill
                 omgInputNumber
                 omgSyncInput
                 step="0.1"
                 data-cy="notes-vitals-ht"
                 type="number"
                 (focus)="focusTextBox.emit('Ht')"
                 (focusout)="focusoutTextBox.emit('Ht')" />
          <span>in</span>
        </label>
      </label>
      <span class="padding-half-vertical"
            om-flex="15"
            om-layout
            om-layout-align="start end">
        <div *ngIf="ordinalizedHeightPercentile$ | ngrxPush as heightPercentile"
             om-layout
             om-layout-gutter-large>
          <span om-flex="15">|</span>
          <span>{{ heightPercentile }}</span>
        </div>
      </span>

      <label *ngIf="!isInfant"
             class="-stacked"
             om-flex="15">
        BMI
        <input [control]="form.get('bodyMassIndex')"
               [value]="form.get('bodyMassIndex').value"
               class="om-input -readonly"
               name="bodyMassIndex"
               omgSyncInput
               readonly
               type="number"
               (focus)="focusTextBox.emit('BMI')"
               (focusout)="focusoutTextBox.emit('BMI')" />
      </label>

      <span *ngIf="!isInfant"
            class="padding-half-vertical"
            om-flex="15"
            om-layout
            om-layout-align="start end">
        <div *ngIf="ordinalizedBmiPercentile$ | ngrxPush as bmiPercentile"
             om-layout
             om-layout-gutter-large>
          <span om-flex="15">|</span>
          <span>{{ bmiPercentile }}</span>
        </div>
      </span>

      <ng-template *ngIf="isInfant"
                   [ngTemplateOutlet]="headCircumferenceVital"
                   [ngTemplateOutletContext]="{ flexNumber: '15'}">
      </ng-template>
    </div>

    <div class="-immediate-errors"
         om-layout
         om-layout-gutter-large>
      <label class="-stacked"
             om-flex="15">
        SpO₂
        <label class="plain-text"
               om-layout
               om-layout-align="center center"
               om-layout-gutter-mini>
          <input [control]="form.get('pulseOximetry')"
                 [max]="100"
                 [min]="0"
                 name="pulseOximetry"
                 om-layout-fill
                 omgInputNumber
                 omgSyncInput
                 type="number"
                 (focus)="focusTextBox.emit('SpO2')"
                 (focusout)="focusoutTextBox.emit('SpO2')" />
          <span>%</span>
        </label>
      </label>

      <label class="-stacked"
             om-flex="15">
        FiO₂
        <label class="plain-text"
               om-layout
               om-layout-align="center center"
               om-layout-gutter-mini>
          <input [control]="form.get('oxygenConcentration')"
                 [max]="100"
                 [min]="0"
                 name="oxygenConcentration"
                 om-layout-fill
                 omgInputNumber
                 omgSyncInput
                 type="number"
                 (focus)="focusTextBox.emit('FiO2')"
                 (focusout)="focusoutTextBox.emit('FiO2')" />
          <span>%</span>
        </label>
      </label>

      <ng-template *ngIf="displayChildHeadCircForThreeAndUnder$ | ngrxPush"
                   [ngTemplateOutlet]="headCircumferenceVital"
                   [ngTemplateOutletContext]="{ flexNumber: '15'}">
      </ng-template>
    </div>
    <div *ngIf="form.get('bodyMassIndex').invalid"
         class="om-messages">BMI must be in range 0-99</div>
  </ng-template>

  <ng-template #headCircumferenceVital
               let-flexNumber="flexNumber">
    <label class="-stacked"
           [attr.om-flex]="flexNumber ? flexNumber : null">
      head circ
      <label class="plain-text"
             om-layout
             om-layout-align="center center"
             om-layout-gutter-mini>
        <input [control]="form.get('headCircumference')"
               name="head"
               om-layout-fill
               omgInputNumber
               omgSyncInput
               step="0.01"
               type="number"
               [ngClass]="{ 'error': form.get('headCircumference').errors }" />
        <span>cm</span>
      </label>
      <ng-template *ngIf="form.get('headCircumference').errors"
                   [ngTemplateOutlet]="showErrorMessage"
                   [ngTemplateOutletContext]="{ errorMessage: 'Out of range', isAfterUnit: true }">
      </ng-template>
    </label>
    <span class="padding-half-vertical"
          om-layout
          om-layout-align="start end">
      <div *ngIf="ordinalizedHeadCircumferencePercentile$ | ngrxPush as headCircumferencePercentile"
           om-layout
           om-layout-gutter-large>
        <span>|</span>
        <span>{{ headCircumferencePercentile }}</span>
      </div>
    </span>
  </ng-template>

</div>
