<omg-card class="workspace-card"
          [loading]="loading$ | ngrxPush"
          [empty]="empty$ | ngrxPush">
  <omg-card-title heading="Pending Requests">
  </omg-card-title>
  <omg-patient-incomplete-demographics></omg-patient-incomplete-demographics>
  <ng-container *ngIf="cartComplete$ | ngrxPush">
    <omg-pdmp-rx-dialogue *ngIf="cartDetails?.hasControlledSubstances && (showPDMPDialogue$ | ngrxPush)">
    </omg-pdmp-rx-dialogue>
    <omg-completed-renewals></omg-completed-renewals>
  </ng-container>
  <ng-container *ngIf="changeRxSubmitAction$ | ngrxPush as changeRxSubmitAction">
    <omg-completed-change-rx [changeRxSubmitAction]="changeRxSubmitAction"></omg-completed-change-rx>
  </ng-container>
  <ng-template #loadedRef>
    <omg-card-body>
      <router-outlet></router-outlet>
    </omg-card-body>
    <omg-card-footer>
    </omg-card-footer>
  </ng-template>
  <ng-template #loadingRef>
    <omg-feature-loading-spinner></omg-feature-loading-spinner>
  </ng-template>
  <ng-template #emptyRef>
    <ng-container *ngIf="changeRxEnabled$ | ngrxPush; else noRenewals">
      No Pending Requests
    </ng-container>
    <ng-template #noRenewals>
      No Pending Renewals
    </ng-template>
  </ng-template>
</omg-card>
