import { Injectable, OnDestroy } from '@angular/core';
import { Angulartics2Mixpanel } from 'angulartics2/mixpanel';
import { Subject } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';
import { filterTruthy } from '@app/utils';
import { ConfigService } from '../../config/config.service';
import { PatientSelectors } from '../../patient';
import { ProfileSelectors } from '../../profile';
import { TrackEventProperties } from './analytics.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { FeatureFlagWhitelist } from './feature-flag-whitelist';
import { LaunchDarklyExperiment } from './launch-darkly-experiment';

declare let mixpanel;

interface MixPanelConfig {
  token: string;
  application: string;
}

@Injectable()
export class AnalyticsService implements OnDestroy {

  instanceProperties: Partial<TrackEventProperties>;

  private unsubscribe = new Subject<void>();
  private _initialized = false;

  constructor(
    config: ConfigService,
    private angulartics2MixPanel: Angulartics2Mixpanel,
    private profileSelectors: ProfileSelectors,
    private patientSelectors: PatientSelectors,
    private launchdarkly: LaunchDarklyService,
  ) {
    this.initialize(config.environment.mixPanel);
  }

  get initialized() {
    return this._initialized;
  }

  /* istanbul ignore next */
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private setUsername(username: string) {
    mixpanel.identify(username);
  }

  private setSuperProperties(properties: Partial<TrackEventProperties>) {
    mixpanel.register({ ...properties });
  }

  private initialize(config: MixPanelConfig) {
    if (!config.token || !mixpanel || this.initialized) {
      return;
    }

    mixpanel.init(config.token, { track_pageview: false });
    this.angulartics2MixPanel.startTracking();

    this.instanceProperties = {
      sessionFingerprint: Date.now(),
      application: config.application,
    };

    this.setSuperProperties(this.instanceProperties);
    this.subscribeToPatientChanges();
    this.subscribeToProfileChanges();
    this.subscribeToLaunchDarkly();

    this._initialized = true;
  }

  private subscribeToPatientChanges(): void {
    this
      .patientSelectors
      .patientId
      .pipe(
        filterTruthy(),
        takeUntil(this.unsubscribe),
      )
      .subscribe(patientId => {
        this.instanceProperties = {
          ...this.instanceProperties,
          patientId,
        };
        this.setSuperProperties(this.instanceProperties);
      });
  }

  private subscribeToProfileChanges(): void {
    this.profileSelectors.profileId
      .pipe(filterTruthy(), takeUntil(this.unsubscribe))
      .subscribe(profileId =>
        this.setUsername(profileId ? profileId.toString() : ''),
      );
  }

  private subscribeToLaunchDarkly(): void {
    this
      .launchdarkly
      .initialized$
      .pipe(
        first(() => this.launchdarkly.variation(FeatureFlagNames.mixpanelEventsWithExperimentData, false)),
      )
      .subscribe(() => {
        const experiments = FeatureFlagWhitelist
          .map(flag => this.experiment(flag))
          .filter(experiment => experiment.detail.reason.kind !== 'OFF');
        this.instanceProperties = {
          ...this.instanceProperties,
          experiments,
        };
        this.setSuperProperties(this.instanceProperties);
      });
  }

  private experiment(flag: FeatureFlagNames): LaunchDarklyExperiment {
    return {
      name: flag,
      detail: this.launchdarkly.variationDetail(flag),
    };
  }
}
